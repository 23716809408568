import axios from 'axios';

export default async function (file) {
  // const url = process.env.VUE_APP_SERVER_API_UOA+'upload';
  // const formData = new FormData();
  // formData.append('files', file);
  // const config = {
  //   headers: {
  //     'content-type': 'multipart/form-data'
  //   }
  // };
  // return axios.post(url, formData, config);

  let base64 = await toBase64(file);
  const url = process.env.VUE_APP_SERVER_API_UOA+'upload-firebase';
  let resSignature = await axios.post(url, {uri:base64})

  return resSignature.data.Url;
};

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


