<template>
  <div id="manageAircraft" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Aircrafts</div>
      </div>
    </div>

    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="itemsdata">
          <template v-slot:item.ac_status="">
            <div class="tag tagStatusActive">Active</div>
            <!-- tagStatusActive,tagStatusMaintenance,tagStatusUnactive -->
          </template>
          <!-- <template v-slot:item.ac_seat_layout_url="{ item }">
            <img class="B-img-airport" :src="item.ac_seat_layout_url" />
          </template> -->
          <template v-slot:item.ac_last_update_status="{ item }">
            <div @click="GotoAircraftLog(item.ac_id)">{{ item.ac_last_update_status | yyyyMMdd }}</div>
            <!-- tagStatusActive,tagStatusMaintenance,tagStatusUnactive -->
          </template>

          <template v-slot:item.ac_id="{ item }" class="text-xs-right">
            <div class="b-action">
              <v-btn
                class="theme-btn-even"
                :to="{
                  name: 'AircraftDetail',
                  params: { mode: modeNow, idflight: idflightNow, idaircraft: item.ac_id },
                }"
              >
                <span>VIEW</span>
                <span class="I-arrrowR"></span>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- dialog สำหรับ เพิ่ม แก้ไข -->
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <!-- set form กรอกข้อมูลที่นี้ -->
              <v-flex sm6 xs6 md6 lg6>
                <v-text-field
                  v-model="formModel.ac_name"
                  label="Name"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 xs6 md6 lg6>
                <v-text-field
                  v-model="formModel.ac_model"
                  label="Model"
                ></v-text-field>
              </v-flex>

              <v-flex sm6 xs6 md6 lg6>
                <v-text-field
                  v-model="formModel.ac_total_seat"
                  label="Total Seat"
                ></v-text-field>
              </v-flex>

              <v-flex sm6 xs6 md6 lg6>
                <v-text-field
                  v-model="formModel.ac_limit_weight"
                  label="Limit Weight"
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                Seat Layout
                <uploadform @success="uploadDone">
                  <div slot="activator">
                    <v-flex xs12 text-xs-center>
                      <v-img
                        :aspect-ratio="4 / 3"
                        :src="formModel.ac_seat_layout_url"
                        max-width="500"
                      ></v-img>
                    </v-flex>
                  </div>
                </uploadform>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="saveToServer">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog สำหรับลบ -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Confirmation</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                >Delete "{{ formModel[Object.keys(formModel)[1]] }}" ?</v-flex
              >
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="loading" @click="deleteToServer">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn
          text
          class="ColorTheme-S white--text theme-btn"
          :to="{
            name: 'FlightDetail',
            params: { mode: 'Edit', id: idflightNow },
          }"
        >
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import upload from "../utils/upload";
import uploadform from "../components/upload/ImageInput.vue";
import fillerdate from "../utils/filterdate";

export default {
  data: () => ({
    //--start config
    headers: [
      { value: "ac_status", text: "Status", sortable: true },
      { value: "ac_name", text: "Name", sortable: true },
      { value: "ac_model", text: "Model", sortable: true },
      { value: "ac_last_update_status", text: "Last Update", sortable: true },
      // { value: "ac_seat_layout_url", text: "Seat Layout", sortable: true },
      // { value: "ac_total_seat", text: "Total Seat", sortable: true },
      // { value: "ac_limit_weight", text: "Limit Weight", sortable: true },
      { value: "ac_id", text: "", sortable: false },
    ],
    defaultValue: {
      ac_status: "",
      ac_name: "",
      ac_model: "",
      ac_seat_layout_url: "/img/SeatLayout.png",
      ac_total_seat: null,
      ac_limit_weight: null,
    },
    //--end config

    itemsdata: [],
    loading: false,
    dialog: false,
    dialogDelete: false,
    formModel: {},
    mode: "", // มีได้ 2 แบบคือ create กับ edit

    //Add
    idflightNow: 0,
    modeNow:"",
  }),
  props: ["mode", "idflight"],
  components: {
    uploadform,
  },
  computed: {
    formTitle() {
      return this.mode === "create" ? "Add" : "Edit";
    },
  },
  async mounted() {
    this.modeNow =this.$route.params.mode;
    this.idflightNow = this.$route.params.idflight;
    //alert(this.$route.params.mode + " " + this.$route.params.idflight);
    //init here
    await this.renderUI();
  },
  methods: {
    async renderUI() {
      try {
        const q = {};
        q.retire = 0;
        var res = await feathersClientUOA
          .service("aircrafts")
          .find({ query: q });
        this.itemsdata = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้ Aircraft");
      }
    },
    async addItem() {
      this.mode = "create";
      this.formModel = Object.assign({}, this.defaultValue);
      this.dialog = true;
    },

    async editItem(item) {
      this.mode = "edit";
      this.formModel = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      this.formModel = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
    },
    async saveToServer() {
      this.loading = true;
      if (this.mode === "edit") {
        try {
          let dataEdit = {
            ac_name: this.formModel.ac_name,
            ac_model: this.formModel.ac_model,
            ac_seat_layout_url: this.formModel.ac_seat_layout_url,
            ac_total_seat: this.formModel.ac_total_seat,
            ac_limit_weight: this.formModel.ac_limit_weight,
            mb: "GEM 2",
            md: new Date(),
          };
          await feathersClientUOA
            .service("aircrafts")
            .patch(this.formModel.ac_id, dataEdit);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        //Add Data
        try {
          let dataAdd = {
            ac_name: this.formModel.ac_name,
            ac_model: this.formModel.ac_model,
            ac_seat_layout_url: this.formModel.ac_seat_layout_url,
            ac_total_seat: this.formModel.ac_total_seat,
            ac_limit_weight: this.formModel.ac_limit_weight,
            cb: "GEM 2",
            cd: new Date(),
          };
          await feathersClientUOA.service("aircrafts").create(dataAdd);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
        let dataDelete = {
          mb: "GEM 2",
          md: new Date(),
          retire: 1,
        };
        await feathersClientUOA
          .service("aircrafts")
          .patch(this.formModel.ac_id, dataDelete);
        this.renderUI();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    },
    uploadDone(uploadInfo) {
      this.formModel.ac_seat_layout_url = uploadInfo.url;
    },

    GotoAircraftLog(aircraftid){
      alert("GotoAircraftLog : " + aircraftid)
    }
  },
};
</script>